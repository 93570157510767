import request from '@/core/services/api.services.request'

export function getAllBackorder(params) {
  return request({
    url: 'back/order/list',
    method: 'get',
    params: params
  })
}

export function getBackorderView(backorder_id) {
  return request({
    url: '/back/order/view/' + backorder_id,
    method: 'get',
  })
}